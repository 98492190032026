






















import { defineComponent, computed, onBeforeMount } from "@vue/composition-api";
import { Dog, DogTrainingCreateBody, User } from "@/store";
import DogTrainingForm from "@/components/forms/DogTraining.vue";
import {
  useStore,
  useUserService,
  useDogService,
  useRouter,
} from "@/composables/providers";
import { UserActions, UserGetters } from "@/store/user";
import { DogActions, DogGetters } from "@/store/dog";
import { SessionGetters } from "@/store/session";

export default defineComponent({
  name: "DogTrainingsCreate",
  components: {
    DogTrainingForm,
  },
  setup() {
    const store = useStore();
    const userService = useUserService();
    const dogService = useDogService();
    const router = useRouter();
    const usersLoading = computed<boolean>(
      () => store.getters[UserGetters.LOADING]
    );
    const sessionLoading = computed<boolean>(
      () => store.getters[UserGetters.LOADING]
    );
    const dogsLoading = computed<boolean>(
      () => store.getters[DogGetters.LOADING]
    );
    const loading = computed<boolean>(
      () => usersLoading.value || sessionLoading.value || dogsLoading.value
    );

    const dogTrainers = computed<User[]>(
      () => store.getters[UserGetters.DOG_TRAINERS]
    );

    const dogs = computed<Dog[]>(
      () => store.getters[DogGetters.DOGS_SORTED_BY_NAME]
    );

    const currentUser = computed<User>(
      () => store.getters[SessionGetters.USER]
    );

    const isStaffOrHigher = computed<boolean>(
      () => store.getters[SessionGetters.IS_STAFF_OR_HIGHER]
    );

    async function submit(dogId: number | string, body: DogTrainingCreateBody) {
      const dog: Dog = await store.dispatch(DogActions.ADD_TRAINING, {
        id: dogId,
        body: body,
        service: dogService,
      });

      if (dog) {
        await router.push({ name: "Dashboard" });
      }
    }

    onBeforeMount(async () => {
      await Promise.all([
        store.dispatch(UserActions.LIST, { service: userService }),
        store.dispatch(DogActions.LIST, { service: dogService }),
      ]);
    });

    return {
      submit,
      loading,
      dogTrainers,
      currentUser,
      isStaffOrHigher,
      dogs,
    };
  },
});
